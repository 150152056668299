<template>
  <div>
    <b-form @submit.prevent="enviarForm">
      <b-form-row>
        <b-col cols="12" md="3">
          <label for="id_responsable">ID Responsable: </label>
          <b-form-input
            id="iden_responable"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="ID responable"
            v-model="form.iden_responable"
          ></b-form-input>
        </b-col>

        <b-col cols="12" md="6">
          <label for="apellidos_resp">Apelllidos Responsable: </label>
          <b-form-input
            id="apellidos_resp"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Apellidos"
            v-model="form.apellidos_resp"
          ></b-form-input>
        </b-col>

        <b-col cols="12" md="3">
          <label for="iua">IUA mascota: </label>
          <b-form-input
            id="iua"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="IUA"
            v-model="form.iua"
          ></b-form-input>
        </b-col>

        <b-col cols="12" md="3">
          <label for="nombre">Nombre mascota: </label>
          <b-form-input
            id="nombre_animal"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Nombre animal"
            v-model="form.nombre_animal"
          ></b-form-input>
        </b-col>

        <b-col cols="12" md="3">
          <label for="fechaInicio">Fecha Inicio:</label>
          <div class="d-flex align-items-center">
            <b-form-datepicker
              id="fechaInicio"
              v-model="form.fecha_inicio"
              class="mb-2 mr-2"
              placeholder="DD/MM/YY"
              locale="es"
              labelNoDateSelected="No hay fecha"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              labelHelp="Use las flechas del teclado para navegar entre fechas"
            ></b-form-datepicker>
            <b-icon
              icon="x-circle"
              variant="danger"
              @click="borrarFechaInicio"
              v-if="this.form.fecha_inicio != null"
              class="mb-1"
            ></b-icon>
          </div>
        </b-col>

        <b-col cols="12" md="3">
          <label for="fechaFin">Fecha Fin:</label>
          <div class="d-flex align-items-center">
            <b-form-datepicker
              id="fechaFin"
              v-model="form.fecha_fin"
              class="mb-2 mr-2"
              placeholder="DD/MM/YY"
              locale="es"
              labelNoDateSelected="No hay fecha"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              labelHelp="Use las flechas del teclado para navegar entre fechas"
            ></b-form-datepicker>
            <b-icon
              icon="x-circle"
              variant="danger"
              @click="borrarFechaFin"
              v-if="this.form.fecha_fin != null"
              class="mb-1"
            ></b-icon>
          </div>
        </b-col>

        <b-col cols="12" md="3" align-self="end">
          <b-button
            class="vertical-align mb-2"
            variant="secondary"
            type="submit"
            block
          >
            <b-icon icon="search"></b-icon>
            Consultar
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
  export default {
    name: "FormularioConsutaAdopcion",
    data() {
      return {
        form: {
          iden_responable: null,
          apellidos_resp: null,
          iua: null,
          nombre_animal: null,
          fecha_inicio: null,
          fecha_fin: null,
        },
      };
    },
    methods: {
      borrarFechaInicio() {
        console.log("aqui");
        this.form.fecha_inicio = null;
      },

      borrarFechaFin() {
        this.form.fecha_fin = null;
      },

      async enviarForm(){
        this.$emit("filtros", this.form)
      },
    },
  };
</script>