<template>
  <div>
    <div class="cajaSuperior">
      <b-row>
        <b-col cols="12" md="3">
          <h2>
            criterios de <br />
            consulta
          </h2>
        </b-col>

        <!-- Formulario consulta -->
        <b-col>
          <div class="contenedorForm pl-5">
            <FormularioConsultaAdopcion @filtros="consultarSolicitudes" />
          </div>
        </b-col>
      </b-row>
    </div>
    <br />
    <br />
    <b-row v-if="solicitudesAdopcionServicio.length > 0">
      <b-col>
        <b-table
          id="consultaLicencias"
          striped
          responsive
          small
          bordered
          :items="solicitudesAdopcionServicio"
          :fields="camposTabla"
          :per-page="perPage"
          :current-page="currentPage"
          :tbody-tr-class="rowClass"
          select-mode="single"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(seleccionar)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="solicitudesAdopcionServicio.length > 0">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="consultaLicencias"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-modal
      id="ModalSolicitud"
      v-model="mostrarModalSolicitud"
      hide-footer
      size="lg"
    >
      <template #modal-title>Solicitud Adopción</template>
      <div class="d-block text-center p-3">
        <SolicitudAdopcion @cerrarModal="cerrarModalSolicitudAdopcion" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import FormularioConsultaAdopcion from "../../../components/animales/adopcion/FormularioConsultaAdopcion.vue";
import SolicitudAdopcion from "../../../components/animales/adopcion/SolicitudAdopcion.vue";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { traerNombreEstadoAdopcion } from "../../../constantes";
import { encontrarError } from '../../../contantesErrores';

export default {
  name: "ConsultaAdopcion",
  components: {
    FormularioConsultaAdopcion,
    SolicitudAdopcion,
  },
  data() {
    return {
      filtros: {},
      mostrarModalSolicitud: false,
      camposTabla: [
        "seleccionar",
        {
          key: "fecha_solicitud",
          formatter: (value) => {
            return moment(value).format("DD-MM-YYYY");
          },
        },
        "nombre_persona_solicita",
        {
          key: "iden_persona_solicita",
          label: "Identificación",
        },
        // {
        //     key: 'telefono',
        //     label: 'Teléfono'
        // },
        "desc_tipo_animal",
        "nombre_animal",
        {
          key: "estado",
          formatter: (value) => {
            let estado = traerNombreEstadoAdopcion(value);
            return estado.nombre;
          },
        },
        {
          key: "nombre_funcionario_aprueba",
          label: "Funcionario Aprueba/Rechaza"
        },
      ],
      itemSeleccionado: [],
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapState("animales", ["solicitudesAdopcionServicio"]),
    rows() {
      return this.solicitudesAdopcionServicio.length;
    },
  },
  methods: {
    ...mapActions("animales", ["buscarSolicitudesAdopcion", "seleccionarSolicitudAdopcion"]),
    rowClass(item, type) {
      if (!item || type !== "row") return;
      return "rowspace";
    },

    consultarSolicitudes(filtrosConsulta) {
      this.filtros = filtrosConsulta;
      this.enviarConsulta()
    },

    async enviarConsulta(){
        let filtros = {
            solicitudadopcionparams: this.filtros,
        };
        try {
            await this.buscarSolicitudesAdopcion(filtros);
        } catch (e) {
            encontrarError(this, e.data);
        }
    },

    onRowSelected(items) {
      if (items.length > 0) {
        let objeto = {
          activarSoloVista: true,
          ...items[0],
        };
        this.seleccionarSolicitudAdopcion(objeto);
        this.mostrarModalSolicitud = true;
      }
    },

    cerrarModalSolicitudAdopcion() {
      this.mostrarModalSolicitud = false;
      this.enviarConsulta();
    },
  },
};
</script>